import React from 'react'
import Layout from '~/layouts/layout'
import SEO from '~/components/seo'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'

const Press = ({ data }) => {
  return (
    <Layout language="en" en="press" nl="pers">
      <SEO
        title="Press"
        keywords={[`valta`, `lamp`, `light`, `press`, `press kit`]}
      />
      <div className="articleHero gridFullWidth">
        <h1>Press</h1>
      </div>
      <article className="gridFullWidth">
        <div className="articleContent">
          <MDXRenderer>{data.mdx.body}</MDXRenderer>
        </div>
        <aside>
          <div className="scrollingMenu"></div>
        </aside>
      </article>
    </Layout>
  )
}

export const query = graphql`
  {
    mdx(frontmatter: { slug: { eq: "press" } }) {
      frontmatter {
        slug
        title
        subtitle
        description
      }
      body
    }
  }
`

export default Press
